const NOTIFICATION_INIT_STATE = {
  open: false,
  type: `info`,
  message: ``,
  notifications: [],
};

export const notificationReducer =  (state = NOTIFICATION_INIT_STATE, action) => {
  switch (action.type) {
    case 'PROCESS_NOTIFY':
    case 'OPEN_NOTIFY':
      return state = {
        ...state,
        open: action.payload.open,
        type: action.payload.type,
        message: action.payload.message
      };
    case 'CLOSE_NOTIFY':
      return state = {
        ...state,
        open: false
      };
    case 'ENQUEUE_SNACKBAR':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };
    case 'REMOVE_SNACKBAR':
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      };
    default:
      return state;
  }
}