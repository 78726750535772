import React, { Component } from 'react';
import { Avatar, Typography } from '@material-ui/core';

class SideUser extends Component {
  render () {
    let { profile, sbOpen } = this.props;
    return (
      <div className="d-flex flex-column mb-3">
        {profile &&
          <>
            <Avatar className="mx-auto"
              alt="Profile Picture"
              src={profile.photo ? profile.photo : `/img/coinoyez-icon.png`}
              style={{width: `70%`, height: `auto`, margin: `10px`}}
            />
            {sbOpen &&
              <Typography className="white-text"
                variant="h6"
                align="center"
              >{profile.displayName}
              </Typography>
            }
          </>
        }
      </div>
    );
  }
}

export default SideUser;
