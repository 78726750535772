import { createStore, combineReducers, applyMiddleware } from 'redux';
// import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { reducer as formReducer} from 'redux-form';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { paymentReducer } from './reducers/paymentReducer';
import { submissionReducer } from './reducers/submissionReducer';
import { notificationReducer } from './reducers/notificationReducer';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  form: formReducer,
  payment: paymentReducer,
  notify: notificationReducer,
  submission: submissionReducer
})

export default createStore(rootReducer, {}, applyMiddleware(thunkMiddleware, /*createLogger*/));
