import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const prodConfig = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
};

// const devConfig = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
//   projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
//   apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DEV_FIREBASE_DB_URL,
//   storageBucket: process.env.REACT_APP_DEV_FIREBASE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_SENDER_ID
// };

// const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

firebase.initializeApp(prodConfig);

const auth = firebase.auth();
const gooAuth = new firebase.auth.GoogleAuthProvider();
const db = firebase.firestore();
db.settings({timestampsInSnapshots: true});

export {
  gooAuth, auth, db, firebase
};