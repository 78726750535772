export const enqueueSnackbar = notification => {
  return {
    type: 'ENQUEUE_SNACKBAR',
    notification: {
      key: new Date().getTime() + Math.random(),
      ...notification,
    },
  }
}

export const removeSnackbar = (key) => {
  return {
    type: 'REMOVE_SNACKBAR',
    key,
  }
}