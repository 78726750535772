import React from 'react';
import {Navbar, NavbarBrand, NavbarNav, NavItem, NavLink, NavbarToggler, Collapse} from "mdbreact";
import * as routes from '../constants/route';

class MainNav extends React.Component {
  constructor(props){
    super();
    this.state = {
      isOpen: false
    }
  }
  toggleCollapse = () => {
    const {isOpen} = this.state;
    this.setState({ isOpen: !isOpen });
  }
  render() {
    const {isOpen} = this.state;
    return (
      <Navbar color="white" light expand="md" className="py-0">
        <div className="col-12 set-width d-flex flex-wrap justify-content-between mx-auto px-0">
          <NavbarBrand>
            <NavLink to={routes.HOME}><img className="img-fluid" src="/img/co-main-logo.png" alt="CoinOyez"/></NavLink>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleCollapse}/>
          <Collapse id="navbarCollapse3" isOpen={isOpen} navbar>
            <NavbarNav style={{height:`100%`}} right>
              {/* <NavItem><NavLink className="px-2 px-lg-3" to={routes.HOME}>Home</NavLink></NavItem>
              <NavItem>
                <Dropdown>
                  <DropdownToggle nav caret>
                    <div className="d-inline">Our PR Services</div>
                  </DropdownToggle>
                  <DropdownMenu className="col-12">
                    <DropdownItem href="#!">Action</DropdownItem>
                    <DropdownItem href="#!">Another Action</DropdownItem>
                    <DropdownItem href="#!">Something else here</DropdownItem>
                    <DropdownItem href="#!">Something else here</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem> */}
              <NavItem className="mt-auto pb-3 navBar-hover"><NavLink className="px-2 px-lg-3 pb-1" to={routes.NEWS}>Newsroom</NavLink></NavItem>
              <NavItem className="mt-auto pb-3 navBar-hover"><NavLink className="px-2 px-lg-3 pb-1" to={routes.ABOUT}>About</NavLink></NavItem>
              <NavItem className="mt-auto pb-3 navBar-hover"><NavLink className="px-2 px-lg-3 pb-1" to={routes.PARTNERS}>Partners</NavLink></NavItem>
              <NavItem className="mt-auto pb-3 navBar-hover"><NavLink className="px-2 px-lg-3 pb-1 font-weight-normal" to={routes.CONTACT}>Contact Us</NavLink></NavItem>
            </NavbarNav>
          </Collapse>
        </div>
      </Navbar>
    );
  }
}

export default MainNav;