import * as credit from '../constants/creditIncrementAmt';
import { updateCredits } from '../functions/fsFunc';

const PAYMENT_INIT_STATE = {
  balCredit: 0,
  price: 0,
  chargedCredits: 0,
  reset: {},
  products: [],
  order: {}
};

const paymentReducer = (state = PAYMENT_INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_ORDER':
      return state = {
        ...state,
        order: action.payload.orderTag
      };
    case 'SET_PROCESSING': {
      const newOrder = {
        ...state.order,
        orderid: action.payload.orderid,
        userid: action.payload.userid
      };
      updateCredits(newOrder);
      return state = {
        ...state,
        order: newOrder
      };
    }
    case 'INIT_CREDITBAL':
      return state = {
        ...state,
        balCredit: action.payload.currCreditBal
      };
    case 'ADD_CREDS': {
      let newPrice = state.price + action.payload.topUpPrice
      state.products[0] = {product: credit.CRED_TOPUP_PACK, price: newPrice};
      return state = {
        ...state,
        price: newPrice
      }
    }
    case 'ADD_INC_CREDS': {
      let newPrice = state.price + credit.INC_AMT
      newPrice = (newPrice <= credit.MAX_CRED_LIMIT) ? newPrice : credit.MAX_CRED_LIMIT
      state.products[0] = {product: credit.CRED_TOPUP_PACK, price: newPrice};
      return state = {
        ...state,
        price: newPrice
      }
    }
    case 'ADD_DEC_CREDS': {
      let newPrice = state.price - credit.INC_AMT;
      newPrice = newPrice < 0 ? 0 : newPrice
      state.products[0] = {product: credit.CRED_TOPUP_PACK, price: newPrice};
      return state = {
        ...state,
        price: newPrice
      }
    }
    case 'ADD_PACK': {
      const { product, price, name } = action.payload;
      let discount = 0;
      let newBalCredit = 0;
      let newPrice = 0;
      let newChargedCredits = 0;
      newChargedCredits = state.chargedCredits + price;
      if(state.balCredit > 0) {
        newBalCredit = state.balCredit - price;
        newChargedCredits = state.chargedCredits + price;
        discount = price;
        if(newBalCredit < 0) {
          discount = state.balCredit;
          newPrice = Math.abs(newBalCredit);
          newBalCredit = 0;
        }
      } else {
        newPrice = state.price + price;
      }
      state.products.push({product, discount, price, name});
      return state = {
        ...state,
        balCredit: newBalCredit,
        price: newPrice,
        chargedCredits: newChargedCredits,
        reset: {
          ...state.reset,
          [`${action.payload.product}`]: true
        }
      };
    }
    case 'REMOVE_PACK': {
      const { product, price } = action.payload;
      let newBalCredit = 0;
      let newPrice = 0;
      let newChargedCredits = 0;
      newChargedCredits = state.chargedCredits - price;
      let newCartProducts = state.products.filter(cartProduct => {
        if(cartProduct.product === product){
          if(state.price > 0) {
            if(state.price > price){
              newPrice = state.price - price;
            } else {
              newPrice = state.price - price;
              newBalCredit = state.balCredit + Math.abs(newPrice);
              newPrice = 0;
            }
          } else {
            newBalCredit = state.balCredit + price;
          }
          return null;
        }
        return cartProduct;
      })
      return state = {
        ...state,
        balCredit: newBalCredit,
        price: newPrice,
        chargedCredits: newChargedCredits,
        products: newCartProducts,
        reset: {
          ...state.reset,
          [`${product}`]: false
        }
      };
    }
    case 'RESET_CART':
      return state = {
        ...state,
        ...PAYMENT_INIT_STATE,
        products: [],
        balCredit: action.payload.currCreditBal
      };
    default:
      return state;
  }
};

export { paymentReducer };