import React from 'react';
import * as colors from '../constants/themeColors';
import { withStyles, LinearProgress, CircularProgress  } from '@material-ui/core';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
    color: colors.COGOLD
  },
  linearHeight: {
    height: 5
  },
  linearColor: {
    backgroundColor: colors.COGOLD
  }
});

const LinearLoader = withStyles(styles)(({classes}) => 
  <div className="flex-grow-1" style={{height:`100vh`}}>
    <LinearProgress classes={{root: classes.linearHeight, barColorPrimary: classes.linearColor}} />
  </div>
)

const CircleLoader = withStyles(styles)(({classes}) => 
  <CircularProgress className={classes.progress}/>
)

export {LinearLoader, CircleLoader}
