export const COGOLD = `#f9a748`;
export const COGOLD_DARK = `#C27815`;
export const COGOLD_LIGHT = `#ffd978`;
export const COPURPLE = `#0b1937`;
export const COPURPLE_DARK = `#000013`;
export const COPURPLE_LIGHT = `#363f61`;

export const CLR_DRAFT = `#AAAAAA`;
export const CLR_PENDING = `#F8A64A`;
export const CLR_APPROVED = `#4CAF50`;
export const CLR_REJECTED = `#F44336`;
export const CLR_DEFAULT = `#AAAAAA`;

export const fetchStatusColors = (status) => {
  let statusColor = `#AAAAAA`;
  switch(status) {
    case `draft`:
      statusColor = `#AAAAAA`;
      break;
    case `pending`:
      statusColor = `#F8A64A`;
      break;
    case `approved`:
      statusColor = `#4CAF50`;
      break;
    case `rejected`:
      statusColor = `#F44336`;
      break;
    default:
      statusColor = `#AAAAAA`;
  }
  return statusColor;
}