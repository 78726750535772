import React, {Component} from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { SnackbarProvider } from 'notistack';
import { withStyles, AppBar, Divider, IconButton, Toolbar, Typography } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Drawer } from '@material-ui/core';
import { WritePR, ManagePR, AccUsr, LogOut, TopUp, MenuBar, CloseIcon, LeftArrow, RightArrow } from './icons/DashIcons';
import { sideNav } from './styles/sideNavStyle';
import { handleLogOut } from '../actions/submissionActions';
import NotificationPopUp from './customer-pages/NotificationPopUp';
import withAuthorization from './withAuthorization';
import AuthUserContext from './AuthUserContext';
import SideUser from './SideUser';
import * as routes from '../constants/route';

const sideNavs = [{
  key: 'submitPR',
  text: 'Submit Press Release',
  to: routes.SUBMITPR,
  icon: <WritePR />
},  {
  key: 'managePR',
  text:'Manage Press Release',
  to: routes.MANAGEPR,
  icon: <ManagePR />
}, {
  key: 'topUp',
  text:'Top Up Credits',
  to: routes.TOPUP,
  icon: <TopUp />
}, {
  key: 'accSetting',
  text: 'Account Settings',
  to: routes.ACCOUNT,
  icon: <AccUsr />
}];

class ResponsiveDrawer extends Component {
  state = {sbOpen: true};
  handleDrawerOpen = () => {this.setState({ sbOpen: true });};
  handleDrawerClose = () => {this.setState({ sbOpen: false });};
  render() {
    const { classes, theme, component, name, profile, handleLogOut } = this.props;
    const {sbOpen} = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: sbOpen,
          })}
        >
          <Toolbar disableGutters={!sbOpen}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: sbOpen,
              })}
            ><MenuBar/></IconButton>
            <Typography variant="h6" color="inherit" noWrap>{name}</Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: sbOpen,
            [classes.drawerClose]: !sbOpen,
          })}
          classes={{
            paper: classNames(classes.drawerBg, {
              [classes.drawerOpen]: sbOpen,
              [classes.drawerClose]: !sbOpen,
            }),
          }}
          open={sbOpen}
        >
        <div className={classes.toolbar}>
          <IconButton onClick={this.handleDrawerClose}>
            {theme.direction === 'rtl' ? <RightArrow /> : <LeftArrow />}
          </IconButton>
        </div>
        {sbOpen &&
        <div className="d-flex justify-content-center">
          <img className="img-fluid"  style={{height:`35px`}} src="/img/coin-text-logo.png" alt="CoinOyez"/>
        </div>
        }
        {this.context ?
          <SideUser profile={profile} sbOpen={sbOpen}/> : null
        }
        <Divider className={classes.divider}/>
        <List>
          {sideNavs.map((nav) => (
            <Link href="#!" to={nav.to} key={nav.key}>
              <ListItem button={true} key={nav.key} className={classes.listItem}>
                <ListItemIcon className={classes.listIcon}>{nav.icon}</ListItemIcon>
                <ListItemText primary={<Typography type="body2" color="inherit">{nav.text}</Typography>}/>
              </ListItem>
            </Link>
          ))}
          <ListItem button key="signOut" onClick={handleLogOut} className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}><LogOut/></ListItemIcon>
            <ListItemText primary={<Typography type="body2" color="inherit">Logout</Typography>}/>
          </ListItem>
        </List>
        </Drawer>
        <main className={classNames(classes.content, {
            [classes.contentOpen]: sbOpen,
            [classes.contentClose]: !sbOpen,
          })}>
          <div className={classes.toolbar} />
          <SnackbarProvider
            maxSnack={3}
            action={[
              <IconButton size="small"><CloseIcon/></IconButton>
            ]}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>{component}
            <NotificationPopUp/>
          </SnackbarProvider>
        </main>
      </div>
    );
  }
}

ResponsiveDrawer.contextType = AuthUserContext;

const mapStateToProps = ({firebase: {profile}}) => {
  return {
    profile,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({handleLogOut},dispatch);

const authCondition = (authUser) => !!authUser;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sideNav, {withTheme: true}),
  withAuthorization(authCondition),
)(ResponsiveDrawer);