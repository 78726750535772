import axios from 'axios';
import {firebase} from '../firebase';

const prRef = firebase.db.collection(`pressRelease`);

export const fetchFeaturedPress = async (pressAmt = 4) => {
  const pressDoc = await prRef.where(`featured`, `==`, true).orderBy(`pubtimeTimestamp`, `desc`).limit(pressAmt).get();
  let allPress = [];
  pressDoc.forEach(press => {
    allPress.push({
      ...press.data()
    })
  })
  return allPress;
}

export const fetchLatestPress = async (pressAmt = 5) => {
  const pressDoc = await prRef.where(`status`, `==`, `approved`).orderBy(`pubtimeTimestamp`, `desc`).limit(pressAmt).get();
  let allPress = [];
  pressDoc.forEach(press => {
    allPress.push({
      ...press.data()
    })
  })
  return allPress;
}

const trimPressDetails = async (press) => {
  await Object.entries(press).forEach(([key, val]) => {
    if(typeof val === `string`) {
      press = {
        ...press,
        [key]: val.trim()
      }
    }
  })
  return press;
}

export const checkTitles = async (title) => {
  const queryApproved = prRef.where(`title`, `==`, title.trim()).where(`status`, `==`, `approved`).limit(1);
  const queryPending = prRef.where(`title`, `==`, title.trim()).where(`status`, `==`, `pending`).limit(1);
  let approvedPR = await queryApproved.get();
  let pendingPR = await queryPending.get();
  return ((approvedPR.empty && pendingPR.empty));
}

export const draftPR = (press) => {
  return savePR(press, `draft`);
}

export const processPR = (press) => {
  return savePR(press, `pending`);
}

const savePR = async (press, status) => {
  try {
    // let api = `http://localhost:5000/coinoyez/us-central1/api/save-draft`;
    // let api = `https://coinoyez.firebaseapp.com/api/save-draft`;
    let api = `https://us-central1-coinoyez.cloudfunctions.net/api/save-draft`;
    if(press.pressid){
      // api = `http://localhost:5000/coinoyez/us-central1/api/update-draft`;
      // api = `https://coinoyez.firebaseapp.com/api/update-draft`;
      api = `https://us-central1-coinoyez.cloudfunctions.net/api/update-draft`;
    }
    if(typeof press.pubtime !== `string`){
      press.pubtime = press.pubtime.format("YYYY-MM-DD HH:mm");
    }
    press = await trimPressDetails(press);
    let res = await axios.post(api, {...press, status})
    console.log(`Success: ${res.data}`);
    return res.data;
  } catch(err) {
    throw err;
  }
}

export const fetchPressByStatus = (uid, status) => {
  return new Promise(resolve => {
    const query = prRef.where(`userid`, `==`, uid);
    if(status){
      query.where(`status`, `==`, status)
        .onSnapshot(allpr => {resolve(allpr)})
    } else {
      query.onSnapshot(allpr => {resolve(allpr)})
    }
  })
}

export const fetchSinglePress = async (pressid) => {
  const press = await prRef.doc(pressid).get();
  return {...press.data()}
}

export const downloadReport = async (pressid) => {
  // let api = `http://localhost:5000/coinoyez/us-central1/press/get-report`;
  let api = `https://us-central1-coinoyez.cloudfunctions.net/press/get-report`;
  try {
    let res = await axios.post(api, {pressid})
    // console.log(res.data);
    return res.data;
  } catch(err) {
    if(err.response.data) {
      console.log(err.response.data);
      throw new Error(err.response.data);
    } else {
      throw new Error(`R1: Oops, something went wrong. Please try again.`);
    }
  }
}

export const statusPR = ({prquery: {userid, status}}) => {
  if((!userid) && (!status)){
    return [];
  }
  let query = {
    collection: `pressRelease`,
    where: [['userid', '==', userid]]
  };
  if(status !== `all`){
    query.where.push(['status', '==', status]);
  }
  return [query];
}

export const singlePR = (props) => {
  if(props.pressid === null){
    return [];
  }
  const pressid = (props.pressid) ? props.pressid : props.press.pressid;

  return [{
    collection: `pressRelease`,
    doc: pressid,
  }];
}

export const getRejectMsg = (props) => {
  if(props.pressid === null){
    return [];
  }
  const pressid = (props.pressid) ? props.pressid : props.press.pressid;

  return [{collection:`pressRelease/${pressid}/rejectionLog`, storeAs: `pressRejection`}];
}
