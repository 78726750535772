const NOTIFICATION_INIT_STATE = {
  blocker: false
};

export const submissionReducer =  (state = NOTIFICATION_INIT_STATE, action) => {
  switch (action.type) {
    case 'OPEN_BLOCK':
      return state = {
        ...state,
        blocker: action.payload.blocker,
      }
    case 'CLEAR_BLOCK':
      return NOTIFICATION_INIT_STATE
    default:
      return state;
  }
}