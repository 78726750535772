import { change } from 'redux-form';
import { auth } from '../firebase';
import * as notify from '../constants/notificationMsg';
import { draftPR, processPR } from '../functions/prFunc';
import { enqueueSnackbar } from './notificationActions';

export const handleLogOut = () => (dispatch) => {
  auth.doSignOut();
  dispatch(handleClearData());
}

export const handleClearData = () => {
  return {
    type: '@@reduxFirestore/CLEAR_DATA'
  }
}

export const handleClearBlocker = () => {
  return {type: 'CLEAR_BLOCK'}
}

export const handleOpenBlocker = (process = false) => {
  return {
    type: 'OPEN_BLOCK',
    payload: {
      blocker: process
    }
  }
}

export const handleSaveDraft = (press) => async (dispatch) => {
  try {
    dispatch(enqueueSnackbar(notify.PROCESSING));
    let pressid = await draftPR(press);
    dispatch(change(`prForm`, `pressid`, pressid));
    dispatch(enqueueSnackbar(notify.DRAFT_SAVED));
  } catch(err) {
    dispatch(enqueueSnackbar({message: err.message, options: notify.ERR_OPT}));
  }
}

export const handleProcessDraft = (press) => async (dispatch) => {
  try {
    dispatch(enqueueSnackbar(notify.PROCESSING));
    dispatch(handleOpenBlocker(true));
    await processPR(press);
  } catch(err) {
    dispatch(enqueueSnackbar({message: err.message, options: notify.ERR_OPT}));
  }
}

export const handleResubmit = (press) => async (dispatch) => {
  try {
    dispatch(enqueueSnackbar(notify.PROCESSING));
    dispatch(handleOpenBlocker(`resubmit`));
    if(press.rejectionLog){
      delete press.rejectionLog
    }
    await processPR(press);
  } catch(err) {
    dispatch(enqueueSnackbar({message: err.message, options: notify.ERR_OPT}));
  }
}