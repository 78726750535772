import * as clr from '../../constants/themeColors';

const drawerWidth = 240;

const sideNav = theme => {
  const drawerCloseWidth = theme.spacing.unit * 7 + 1;
  const drawerCloseWidthSM = theme.spacing.unit * 9 + 1;
  return {
    root: {
      display: 'flex',
      flexGrow: 1,
      backgroundColor: 'white',
    },
    appBar: {
      backgroundColor: clr.COPURPLE_DARK,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawerBg: {
      backgroundColor: clr.COPURPLE,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerCloseWidth,
      [theme.breakpoints.up('sm')]: {
        width: drawerCloseWidthSM,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    content: {
      backgroundColor: 'white',
      minHeight: `100vh`,
      overflowX:`auto`
    },
    contentOpen: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    contentClose: {
      width: `calc(100% - ${drawerCloseWidth}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerCloseWidthSM}px)`,
      },
    },
    divider: {
      background: clr.COGOLD,
    },
    listItem: {
      color: clr.COGOLD,
      '&:hover': {
        backgroundColor: clr.COPURPLE_LIGHT,
      }
    },
    listIcon: {
      color: clr.COGOLD,
    }
  }
};

export { sideNav };