import { auth, gooAuth } from './firebase';

/**
 * Sign Up
 * @param {string} email 
 * @param {string} password 
 *  
 * @callback {object} created user object
 */
export const doCreateUserWithEmailAndPassword = (email, password) => 
  auth.createUserWithEmailAndPassword(email, password);

//Sign In
export const doSignInWithEmailAndPassword = (email, password) => 
  auth.signInWithEmailAndPassword(email, password);

//Sign Out
export const doSignOut = () => 
  auth.signOut();

//Password Reset
export const doPasswordReset = (email) => 
  auth.sendPasswordResetEmail(email);

//Password Change
export const doPasswordUpdate = (password) => 
  auth.currentUser.updatePassword(password);

//Sign In With Google Login Popup
export const doGooSignIn = () => 
  auth.signInWithPopup(gooAuth);
