import React from 'react';

// import { Link } from 'react-router-dom';
import { NavbarNav, NavItem, NavLink } from "mdbreact";
import AuthUserContext from './AuthUserContext';
import SignOutButton from './SignOut';
import * as routes from '../constants/route';

const Navigation = () =>
  <AuthUserContext.Consumer>
    {
      authUser => authUser ? <NavigationAuth /> : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>;

const NavBarNavStyle = {
  fontSize:`0.7em`
};

// const NavLinkStyle = {

// };

const NavigationAuth = () =>
  <NavbarNav right style={NavBarNavStyle} className="flex-row">
    <NavItem><SignOutButton /></NavItem>
    <NavItem><NavLink className="py-0 px-3" to={routes.MANAGEPR}>My Account</NavLink></NavItem>
  </NavbarNav>

const NavigationNonAuth = () =>
  <NavbarNav right style={NavBarNavStyle} className="flex-row">
    <NavItem><NavLink className="py-0 px-3" to={routes.SIGNUP}>Sign Up</NavLink></NavItem>
    <NavItem><NavLink className="py-0 px-3" to={routes.SIGNIN}>Sign In</NavLink></NavItem>
  </NavbarNav>

export default Navigation;