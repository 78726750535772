import { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';
import { removeSnackbar } from '../../actions/notificationActions';

class NotificationPopUp extends Component {
  displayed = [];

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i++) {
      if (notExists) continue;
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const {enqueueSnackbar, removeSnackbar, notifications = []} = this.props
    notifications.forEach(notification => {
      let { key, message, options } = notification;
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(key)) return;
      // Display snackbar using notistack
      enqueueSnackbar(message, options);
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(key);
      // Dispatch action to remove snackbar from redux store
      removeSnackbar(key);
    });
  }
  render() { return null; }
}

const mapStateToProps = (store) => {
  return ({
    notifications: store.notify.notifications,
  })
}

const mapDispatchToProps = (dispatch) => bindActionCreators({removeSnackbar}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar,
)(NotificationPopUp);

