import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Helmet } from "react-helmet";
import { LinearLoader } from './Loaders';
import './App.css';
import withAuthentication from './withAuthentication';
import PublicNavbar from './PublicNavbar';
import SideNav from './SideNav';
import Footer from './Footer';
import * as routes from '../constants/route';
import * as metas from '../constants/siteMetas';

const HomePage = lazy(() => import('./pages/Home'));
const SignInPage = lazy(() => import('./SignIn'));
const SignUpPage = lazy(() => import('./SignUp'));
const PasswordForgetPage = lazy(() => import('./PasswordForget'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const About = lazy(() => import('./pages/About'));
const PressReleases = lazy(() => import('./pages/PressReleases'));
const Partners = lazy(() => import('./pages/Partners'));
const Faq = lazy(() => import('./pages/Faq'));
const NotFound = lazy(() => import('./NotFound'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const SingleNews = lazy(() => import('./pages/SingleNews'));
const News = lazy(() => import('./pages/News'));

const CustAccount = lazy(() => import('./customer-pages/Account'));
const ManagePR = lazy(() => import('./customer-pages/Manage'));
const SubmitPR = lazy(() => import('./customer-pages/Submit'));
const TopUp = lazy(() => import('./customer-pages/TopUp'));

const MainRoutes = [{
    path: routes.HOME,
    component: HomePage
  },{
    path: routes.SIGNIN,
    component: SignInPage
  },{
    path: routes.SIGNUP,
    component: SignUpPage
  },{
    path: routes.RESETPW,
    component: PasswordForgetPage
  },{
    path: routes.CONTACT,
    component: ContactUs
  },{
    path: routes.ABOUT,
    component: About
  },{
    path: routes.PRESS,
    component: PressReleases
  },{
    path: routes.PARTNERS,
    component: Partners
  },{
    path: routes.FAQ,
    component: Faq
  },{
    path: routes.NOTFOUND,
    component: NotFound
  },{
    path: routes.SINGLENEWS,
    component: SingleNews
  },{
    path: routes.NEWS,
    component: News
  },{
    path: routes.PRIVACY,
    component: Privacy
  },{
    path: routes.TERMS,
    component: Terms
  },
];

const MainRoute = ({component: Component, location, ...rest}) => (
  <Route {...rest} render={props => (
    <Suspense fallback={<LinearLoader/>}>
      <TransitionGroup>
        <PublicNavbar/>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Component {...props}/>
        </CSSTransition>
        <Footer/>
      </TransitionGroup>
    </Suspense>
  )}/>
)

const DashRoutes = [{
    path: routes.MANAGEPR,
    component: ManagePR,
    name: "Manage Press Release"
  },{
    path: routes.SUBMITPR,
    component: SubmitPR,
    name: "Submit Press Release"
  },{
    path: routes.ACCOUNT,
    component: CustAccount,
    name: "Account Settings"
  },{
    path: routes.TOPUP,
    component: TopUp,
    name: "Top Up Credits"
  },
];

const DashboardRoute = ({component: Component, name, location, ...rest}) => {
  const params = new URLSearchParams(location.search);
  return (
    <Route {...rest} render={props => (
      <Suspense fallback={<LinearLoader/>}>
        <TransitionGroup>
          <SideNav name={name} component={
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
              <Component {...props} pressid={params.get(`press`)}/>
            </CSSTransition>
          }/>
        </TransitionGroup>
      </Suspense>
    )}/>
  )
}

const SiteMeta = () => {
  return (
    <Helmet
      defaultTitle={metas.siteName}
      titleTemplate={`${metas.siteName} | %s`}
    >
      <meta name="description" content={metas.siteDesc}/>

      <meta property="og:title" content={metas.siteTitle}/>
      <meta property="og:site_name" content={metas.siteName}/>
      <meta property="og:url" content={metas.siteUrl}/>
      <meta property="og:description" content={metas.siteDesc}/>
      <meta property="og:image" content={metas.logoImg}/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={metas.siteTitle}/>
      <meta name="twitter:description" content={metas.siteDesc}/>
      <meta name="twitter:image" content={metas.logoImg}/>
      <meta name="twitter:image:alt" content={metas.siteName}/>
    </Helmet>
  )
}

const App = () => {
  return (
    <>
    <SiteMeta/>
    <Router>
      <Switch>
        {MainRoutes.map((mRoute) =>
          <MainRoute
            key={mRoute.path}
            exact path={mRoute.path}
            component={mRoute.component}
          />
        )}
        {DashRoutes.map((dRoute) =>
          <DashboardRoute
            key={dRoute.path}
            exact path={dRoute.path}
            component={dRoute.component}
            name={dRoute.name}
          />
        )}
        <Redirect to={routes.NOTFOUND}/>
      </Switch>
    </Router>
    </>
  )
}

export default withAuthentication(App);
