import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import * as routes from '../constants/route';

const Footer = () => {
  return (
    <section style={{backgroundColor:'#0B1937'}}>
      <div className="set-width d-flex flex-wrap justify-content-between text-left white-text mx-auto py-5">
        <div className="col-12 col-md-4">
          <div className="col-12 p-0"><img className="img-fluid mb-1" src="/img/coin-text-logo.png" alt="CoinOyez"/></div>
          <p>
            <small>Bringing you essential news from the digital currency community. CoinOyez, the efficient, no fuss Press Release full-service platform you need for your blockchain ventures.</small>
          </p>
        </div>
        <div className="col-12 col-md-4">
          <h5>Quick Links</h5>
          <div className="col-12 p-0 d-flex">
            <div className="col-6 p-0 flex-column">
              <ul className="list-unstyled">
                <li><Link className="white-text" to={routes.ABOUT}><small>About</small></Link></li>
                <li><Link className="white-text" to={routes.PARTNERS}><small>Partners</small></Link></li>
                <li><Link className="white-text" to={routes.NEWS}><small>Newsroom</small></Link></li>
                <li><Link className="white-text" to={routes.MANAGEPR}><small>Submit Press Release</small></Link></li>
              </ul>
            </div>
            <div className="col-6 p-0 flex-column">
              <ul className="list-unstyled">
                <li><Link className="white-text" to={routes.FAQ}><small>FAQ</small></Link></li>
                <li><Link className="white-text" to={routes.CONTACT}><small>Contact Us</small></Link></li>
                <li><Link className="white-text" to={routes.PRIVACY}><small>Privacy Policy</small></Link></li>
                <li><Link className="white-text" to={routes.TERMS}><small>Terms of Services</small></Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          {/* <h5>Sign Up For Our Newsletter</h5> */}
        </div>
      </div>
      <div className="set-width text-center white-text py-1 m-auto">
        <p><small>Copyright {moment().format(`YYYY`)} CoinOyez - Press Release Distribution Services - News Release Distribution Services. All Rights reserved.</small></p>
      </div>
    </section>
  );
}

export default Footer;