import axios from 'axios';

const getProductData = async () => {
  const api = `https://us-central1-coinoyez.cloudfunctions.net/api/get-products`;
  try {
    const products = await axios.get(api);
    return await products.data;
  } catch(err){
    throw err;
  }
}

const encryptProduct = async (payload) => {
  const api = `https://us-central1-coinoyez.cloudfunctions.net/api/encrypt-product`;
  try {
    const products = await axios.post(api, {payload});
    return await products.data;
  } catch(err){
    throw new Error(`Payment network error`);
  }
}

const updateCredits = async (order) => {
  // const api = `http://localhost:5000/coinoyez/us-central1/api/update-credits`;
  const api = `https://us-central1-coinoyez.cloudfunctions.net/api/update-credits`;
  try {
    const orderid = await axios.post(api, order);
    console.log(`Order ID: ${orderid.data}`);
  } catch(err){
    console.log(err.message);
    // throw new Error(`Payment network error`);
  }
}

const formatProductPayload = async (products) => {
  let items = [];
  items = products.map(cartProduct => {
    const {product, price, discount = 0} = cartProduct;
    return {
      product,
      quantity: 1,
      pricing: {
        price: {"USD": price},
        discountType: "amount",
        quantityDiscounts: {
          1: {"USD": discount}
        }
      }
    }
  })
  return {items};
}

const formatPromoProductPayload = async (product, price, discount = 0, creditBal = 0) => {
  let pricing = {
    discountType: `amount`,
    price: {
      "USD": price
    }
  }
  if(creditBal !== 0 || discount !== 0) {
    pricing.quantityDiscounts = {
      1: {"USD": creditBal + discount}
    }
  }
  return {
    items: [{
      product,
      quantity: 1,
      pricing,
    }]
  }
}

export { getProductData, encryptProduct, formatProductPayload, formatPromoProductPayload, updateCredits };