import React, {Fragment} from 'react';
import { Navbar } from "mdbreact";
import Navigation from './Navigation';
import MainNav from './MainNav';

const PublicNavbar = () =>
  <Fragment>
    <Navbar color="co-clr" dark expand="md" className="justify-content-center">
      <div className="col-12 set-width px-1 d-flex justify-content-end">
        <Navigation/>
      </div>
    </Navbar>
    <MainNav></MainNav>
  </Fragment>

export default PublicNavbar;