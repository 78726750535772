import React from 'react';
import classNames from 'classnames';
import { withStyles, Icon } from '@material-ui/core';

// const WritePR = () => <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26"><image width="26" height="26" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAWBJREFUSIm9lTtKBEEURU8PjSIaGLgBBcHcH36WoVsQEwU1MjdQAxHchAsw0EGZzFHGTN2AodLBpIJeA6uwaKq7q38+KKgf7/Q7vKYiSdSIa+AN2Cq8KanquNVfnBbdrwvpSno28+OmQRZyadajkoZFsLKQG0fXkbM/LenV7J9IiuuALORO0ouZnzvnG85HTFUF9UyCB7Mec3QdSpqXlJj1nqSoCqhrEgwkdZz9GUeXjf2sPKGQ+xTEjk0HspOXK0RXP+N82dGVC8kD2RYe+HxLWgzRVQSykH6GrpVQXXmgdHf5dNnYDYWkQa4uXyWldflAFvKYAVmroisNujIJsrprtS7EgiTpU/7uWqqjywcatlVJGpSkDtabhEgikiTgC7gAOsAIsG0e4APgrM5b7z7lifxR6j8JqWgOmAW+AZnxATw1UomJ6Ndc+9H5FwoQAz1goUVGBLzHwDgw0SIIYPIH0fRDZk2LhqoAAAAASUVORK5CYII="/></svg>
export const WritePR = () => <Icon className='fas fa-edit fa-fw'/>
// const ManagePR = () => <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="29" height="30" viewBox="0 0 29 30"><image width="29" height="30" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAeCAYAAADQBxWhAAAABHNCSVQICAgIfAhkiAAAAlZJREFUSInF10+IlVUYx/HPvQ1aGYhoLQ5UG+nfphxsgpB2zaGdGLprUR5chOCiVbUpqFy5djEHWrRoEekiUE8EjbaImNRctwgCj1aGo/iPpLkt3lfQ2zT33ph3/MEDL8+B832f9/2d85zTGwwGao5vYB/WY8nqq9fOOx9Sebd3fm72Y7yHb3ET/Q6gAzyAWSxMtcB9IZW5DmD3qOa4BRf6uI2FroEQUrmEU338jU1rAa05PoQHu/h/I3VfoFPLJWuOn+J5HAipfNcptObYwzxexg84VXOcCaks1Bw3amw/iZZCKovDyeHPuxmv4NmQyg5cx5s1xyewiD8njMs1x9dXrBRXcBFf1hy/xgYcx294ta103B3rzso4tyI0pHK75rgNx7Afb4dUvmqHvxkTNlL/MlJI5SKmVwswFhRqjjP4AAc7d+9duorHNO59MaTyY+ve3oTzD0IqV4aT97i35rip5vg7zodUtuMa3rrLvZcnjMWa4+5RlV7Ho1ioOX6GRzQG+gOvtS85GLPKOz30pxWhIZW/ao5P4gQ+xDshlSPt8IkxYSO1nHt/xXOrBRgLCjXHT/AMPgqpnOkcWnMsmmPFOZyuOU6HVM62vXDSrrQUUrk5nBx275YW+HRI5QXcwN6a4+Pt87UJ40bNcdeoSq9qHDxXczyKh3ESl7Dr/1SK71eEtu7dptnkD+H9kMoX7fDRCYH/qeXc+zO2rhZgOd23M1Jf00c7V+vkW32sw1NrAW2X3UtTOIzPa457cEt314o+duKXXnuBOoA9GmN1dYEaaDac/f8AtkjnrduLqg0AAAAASUVORK5CYII="/></svg>
export const ManagePR = () => <Icon className='fas fa-tasks fa-fw'/>
// const AccUsr = () => <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30"><image width="30" height="30" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAfJJREFUSIm91ztrFFEYxvHfrkGJ0UIChoyCCgERDZGIgr1mIAhiIX4AB6wUL52F30AlhSDJEMs0Yu0IgYBaiLlYCRIIomTAG4JoLATXYiYQll2dXWfywDTDc97/Oed9z63WaDSkcdiP29gnU0P5quEzbgZRslxbnRobwAq+YrkC4EYN4iBGe3APv4Io2VsxFKRxuIQHdezH882A5ppDXx3fsH0TwTux1tNNyzQOT+OkrGBeBFHyuNMYHYHTONyDRzjR9H8B54IoeV80Vr0D6Fa8bIbmOob53FMuGNdky6GdduN6FeAzBTxnqwD3FvDsqAL8toBnpQrwRAHP3dLBQZQ8xZ2/WCaCKJkrHZzDb+Ai5vE7/+YRBVFytZNYHe9cQZRMYzqNwwE0gij52GmMrsAbOvCh27Z0ONVlqvCI0zgcwSnZlnkA/bIcf8I7LGI2iJKFUsBpHF6SFdTxNpYh2Ul1Ifcv4n4QJVNdgdM4HMJDjPyrc00axWQah5dxPoiSN61MLXOcV+yrLqAbNYylNA5bHiztiusK+v4Duq7ePFZh8HAJ0HW1nLV2OZ7Bl5LAs4XBQZTM5PDKVJfdMH9WCWnSD2yr4zsObyL4CLbUVqfGDuE1nqHlmitRgxjHeC1/tB2VHfS7VPNgI7uDr+FWECVP/gBGzn5CyhltbAAAAABJRU5ErkJggg=="/></svg>
export const AccUsr = () => <Icon className='fas fa-user-circle fa-fw'/>
// const LogOut = () => <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="26" height="28" viewBox="0 0 26 28"><image width="26" height="28" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAcCAYAAAB/E6/TAAAABHNCSVQICAgIfAhkiAAAAapJREFUSIm91r+L1EAYxvHPLmshCvbjX6CIKHfI4TXaXEAsFbQfG7UTS/8AYQU7i0shtpYWMp2rIqiHgiAIh4U/NpygYKGF4hmLZGWRvfV2Se6pkryZ95tJnjwznbIsQZFnB3EN5/xfgxDTiW3c91e9GnIa9+pr7/AGv9GZMKbE41kg0BmurgQM6wYnQ0yDWZtsRz3crI+XQkzP2oBAF2ex0SZkBIL1NiHjoF07BZrkrkbVa7JZkWcBfdwKMT0ar3UnD5lbe3AeD4s8W2gNFGJax3J9ulbk2WIroBr2BKN4el7k2dFWQDVsgH34iKdFnh3b0gxFni1jReXIcg7eB7zCKTyY5rpLqg/bhHZPA11U5eA8/9imKqhvqB72xZagENNXzJ1/RZ6dqSEvcbwVMxR5dgV3VevaUojpR9PJ0MFVXMdrLISYftJwBOFADXmLIyGmX6NC06/uPS5gcRxCwzMKMX1DPqk2mtFmk8BpoFbc9y/oOw7tBKiPvUWeXW4T1BmurnRVbtmviozb2DA5SDsoQ0xfZgaVZTlagu/jMD7jk+k71Tshpv4soD+0P3aot/c8aQAAAABJRU5ErkJggg=="/></svg>
export const LogOut = () => <Icon className='fas fa-sign-out-alt fa-fw'/>

export const TopUp = () => <Icon className='fas fa-credit-card fa-fw'/>

export const LeftArrow = () => <Icon className='fas fa-chevron-left' style={{color:`white`}}/>

export const RightArrow = () => <Icon className='fas fa-chevron-right' style={{color:`white`}}/>

export const MenuBar = () => <Icon className='fas fa-bars' style={{color:`white`}}/>

const styles = theme => ({
  icon: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    marginLeft: 0,
    marginRight: theme.spacing.unit * 2,
  }
});

export const GooIcon = withStyles(styles)(({classes}) => <Icon className={classNames(classes.icon, 'fab fa-google')} fontSize="large"/>)

export const SaveIcon = () => <Icon className='fas fa-save' fontSize="small"/>

export const CloseIcon = () => <Icon className='fas fa-times' fontSize="small" style={{color:`white`}}/>

export const AddIcon = () => <Icon className='fas fa-plus' fontSize="small" style={{color:`white`}}/>

export const MinusIcon = () => <Icon className='fas fa-minus' fontSize="small" style={{color:`white`}}/>

export const ApprovalIcon = () => <img className="img-fluid mb-5" src="/img/icons/approval.svg" alt="Approvals"/>

export const CheckIcon = () => <img className="img-fluid mb-5" src="/img/icons/check.svg" alt="Check"/>

export const SendIcon = () => <img className="img-fluid m-2" src="/img/icons/send.svg" alt="Send"/>