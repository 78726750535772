import React from 'react';
import { NavLink } from "mdbreact";
import { connect } from 'react-redux';
import * as routes from '../constants/route';
import { handleLogOut } from '../actions/submissionActions';

const SignOutButton = ({logOut}) =>
  <NavLink className="py-0 px-3" to={routes.SIGNIN} onClick={logOut}>
    Sign Out
  </NavLink>

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: (e) => {
      dispatch(handleLogOut(e));
    }
  };
};

export default connect(null, mapDispatchToProps)(SignOutButton);