export const HOME = `/`;
export const SIGNUP = `/signup`;
export const SIGNIN = `/signin`;
export const RESETPW = `/reset-password`;
export const CONTACT = `/contact-us`;
export const ABOUT = `/about`;
export const PRESS = `/press-releases`;
export const PARTNERS = `/partners`;
export const FAQ = `/faq`;
export const PRIVACY = `/privacy`;
export const TERMS = `/terms`;
export const NOTFOUND = `/404`;
export const NEWS = `/news`;
export const SINGLENEWS = `/news/:slug/:pressid`;

export const DASHBOARD = `/dashboard`;
export const ACCOUNT = `/dashboard/account`;
export const SUBMITPR = `/dashboard/submit-pr`;
export const MANAGEPR = `/dashboard/manage-pr`;
export const TOPUP = `/dashboard/top-up`;
